<template>
  <div class="error-page">
    <div class="text-center">
      <Lottie class="lottie-error" path="404.json" />
      <h3 class="my-4">Page not found</h3>
      <v-btn to="/" color="primary">Go Home</v-btn>
    </div>
  </div>
</template>

<script>
import Lottie from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
export default {
  components: {
    Lottie,
  },
};
</script>

<style lang="scss" scoped>
.error-page {
  min-height: 60vh;
  display: grid;
  place-items: center;
}
.lottie-error {
  max-width: 300px;
}
</style>
